import { Maybe } from '@/types';

export const baseSectionContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	marginBottom: '1.25rem',
	padding: '0',
};

export const titleStyles = (titleAlignment: Maybe<string>) => ({
	textAlign: titleAlignment?.toLowerCase() ?? 'center',
});

export const richTextDescStyles = (isDarkBackground: boolean) => ({
	minWidth: '100%',
	'& .MuiLink-root': {
		color: isDarkBackground ? 'text.light' : 'text.interactive',
	},
});
