import { Maybe } from '@/types';

export const templateWrapperStyles = (fullWidth: boolean, useRoundedCorners: boolean) => ({
	position: 'relative',
	margin: '0 auto',
	display: 'flex',
	flexFlow: 'column',
	borderRadius: useRoundedCorners && !fullWidth ? '0.5rem' : '',
});

export const getContentWrapperStyles = ({
	isSmallScreen = false,
	isContentCenterAligned = false,
	inlineLayout = false,
	removePadding = false,
	showSubscriptionForm = false,
	hasIcon = false,
}: {
	isSmallScreen: boolean;
	isContentCenterAligned: boolean;
	inlineLayout: Maybe<boolean>;
	removePadding: Maybe<boolean>;
	showSubscriptionForm: boolean;
	hasIcon: boolean;
}) => {
	const contentDynamicStyles = inlineLayout
		? getContentWrapperInlineStyles(isSmallScreen, isContentCenterAligned, showSubscriptionForm, hasIcon)
		: {
				flexDirection: 'column',
				justifyContent: isSmallScreen ? 'flex-start' : 'center',
				alignItems: isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
				gap: '0 2rem',
				px: isContentCenterAligned || removePadding ? null : { xs: 3, md: '2%', lg: '5%' },
				margin: '0 auto',
				width: '100%',
				flex: 1,
		  };
	return {
		position: 'relative',
		display: 'flex',
		zIndex: 1,
		...contentDynamicStyles,
	};
};

export const getContentWrapperInlineStyles = (
	isSmallScreen: boolean,
	isContentCenterAligned: boolean,
	showSubscriptionForm: boolean,
	hasIcon: boolean
) => ({
	display: isSmallScreen && hasIcon && !showSubscriptionForm ? 'grid' : 'flex',
	gridTemplateColumns: '25% auto',
	gridTemplateRows: 'auto 1fr',
	gridAutoFlow: 'row dense',
	flexDirection: { xs: 'column', md: showSubscriptionForm ? 'column' : 'row' },
	justifyContent: isSmallScreen && isContentCenterAligned ? 'center' : 'flex-start',
	alignItems: !isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
	gap: '1rem',
	py: { xs: 3, md: '2%' },
	px: { xs: 3, md: '2%' },
	'& > .single-message-buttons': {
		marginLeft: 'auto',
	},
	'& > *': {
		gridColumn: { xs: '2 / span 1', md: '' },
		'&.single-message-header-icon': {
			gridColumn: { xs: '1 / span 1', md: '' },
			gridRow: { xs: '1 / span row-end', md: '' },
		},
	},
});

export const getBodyContainerStyles = (isSmallScreen: boolean) => ({
	'.MuiContainer-root': {
		paddingLeft: '0',
		paddingRight: '0',
	},
	'& > ul': {
		paddingLeft: '0.3rem',
		listStylePosition: 'inside',
		listStyleType: { xs: 'none', md: 'disc' },
		fontSize: { xs: '0.875rem', md: '1rem' },
	},
	'& > ul li p': {
		m: 0,
		lineHeight: '2rem',
		display: 'inline-block',
		...(isSmallScreen
			? {
					'&:before': {
						content: '"⦁ "',
					},
			  }
			: {}),
	},
});

export const getContentAlignStyles = (
	isContentCenterAligned: boolean,
	inlineLayout: Maybe<boolean>,
	isSmallScreen: Maybe<boolean>,
	showSubscriptionForm?: Maybe<boolean>
) =>
	inlineLayout
		? getContentAlignInlineStyles(isContentCenterAligned, isSmallScreen, showSubscriptionForm)
		: {
				display: 'flex',
				justifyContent: isContentCenterAligned ? 'center' : 'flex-start',
				alignItems: isContentCenterAligned ? 'center' : 'flex-start',
				alignContent: isContentCenterAligned ? 'center' : 'flex-start',
				width: isContentCenterAligned ? null : '100%',
				maxWidth: isContentCenterAligned ? null : '70rem',
		  };
export const getContentItemAlignStyles = (isContentCenterAligned: boolean) => ({
	marginX: isContentCenterAligned ? 'auto' : '0',
	textAlign: isContentCenterAligned ? 'center' : 'left',
});
export const getContentAlignInlineStyles = (
	isContentCenterAligned: boolean,
	isSmallScreen: Maybe<boolean>,
	showSubscriptionForm?: Maybe<boolean>
) => ({
	display: 'flex',
	flexDirection: isSmallScreen ? 'column' : '',
	alignItems: !isSmallScreen || isContentCenterAligned ? 'center' : 'flex-start',
	width: showSubscriptionForm ? '100%' : 'auto',
	flex: '1 1 auto',
	margin: '0 auto',
	justifyContent: 'space-between',
});

export const getSingleMessageRichTextContainerStyles = (
	isContentCenterAligned: boolean,
	isExtraSmallScreen: Maybe<boolean>,
	showSubscriptionForm: Maybe<boolean>
) => ({
	width: '100%',
	maxWidth: '70rem',
	margin: isContentCenterAligned ? '0 auto' : 0,
	textAlign: isContentCenterAligned ? 'center' : 'left',
	...(isExtraSmallScreen &&
		showSubscriptionForm && {
			paddingBottom: '1.5rem',
		}),
});

export const getSingleMessageButtonsWrapper = (isContentCenterAligned: boolean, inlineLayout: Maybe<boolean>) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: { xs: '0.5rem', md: '1rem' },
	padding: inlineLayout
		? { xs: '2rem 0 0', md: '0' }
		: { xs: isContentCenterAligned ? '0 0.375rem 0.75rem' : '0 0.375rem 0.75rem 0', md: '0 0 1.25rem 0' },
	margin: inlineLayout ? 0 : { xs: '1rem auto 0', md: '1rem 0 0' },
	width: isContentCenterAligned || inlineLayout ? null : '100%',
	maxWidth: isContentCenterAligned ? null : '70rem',
	justifyContent: isContentCenterAligned ? 'center' : 'flex-start',
});

export const backgroundImageStyles = {
	display: 'block',
	backgroundSize: 'cover',
	position: 'absolute',
	top: 0,
	left: 0,
	backgroundRepeat: 'no-repeat',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
	zIndex: 0,
};

export const bannerImageStyles = {
	width: '100%',
	height: 'auto',
	minHeight: '0',
	position: 'static',
	display: 'block',
	objectFit: 'unset',
	objectPosition: '',
};

export const getSideImageGridStyle = (
	isContentCenterAligned: boolean,
	hasLeftImage: boolean,
	hasRightImage: boolean
) => {
	const hasSingleImage = !hasLeftImage || !hasRightImage;
	const centerColumnSize = !isContentCenterAligned && !hasSingleImage ? '37rem' : '1fr';
	const smallDisplayValue = !isContentCenterAligned && hasSingleImage ? 'grid' : 'flex';
	const desktopGridTemplateColumns = `14.125rem ${centerColumnSize} 14.125rem`;
	const mobileGridTemplateColumns = `1fr 1fr 40%`;
	return {
		overflow: 'hidden',
		display: { xs: smallDisplayValue, md: 'grid' },
		flexFlow: { xs: 'column', md: '' },
		gridTemplateColumns: { xs: mobileGridTemplateColumns, md: desktopGridTemplateColumns },
		gridAutoFlow: 'row dense',
		gridGap: '1rem',
		zIndex: 1,
		justifyItems: isContentCenterAligned ? 'center' : 'left',
		margin: '0 auto',
		px: !isContentCenterAligned && hasSingleImage ? '2rem' : 0,
	};
};

export const getGridHeaderStyles = (isHeaderAboveSideImages: boolean, isTitleCenterAligned: boolean) => {
	const belowImagesStyle = isTitleCenterAligned ? `2 / span 1` : `1 / span 2`;
	return {
		gridColumn: isHeaderAboveSideImages ? `1 / span 3` : belowImagesStyle,
		gridRow: isHeaderAboveSideImages ? '1 / span 1' : 1,
	};
};

export const getGridContentStyles = (
	isContentCenterAligned: boolean,
	hasLeftImage: boolean,
	hasRightImage: boolean
) => {
	const gridPosition = isContentCenterAligned || hasLeftImage ? 2 : 1;
	const gridSpan = !isContentCenterAligned && (!hasLeftImage || !hasRightImage) ? 2 : 1;
	return {
		gridColumn: `${gridPosition} / span ${gridSpan}`,
		gridRow: '2 / span row-end',
	};
};

export const getSideImageBoxStyles = (side: 'left' | 'right', isHeaderAboveSideImages: boolean) => ({
	justifyContent: 'center',
	alignItems: 'center',
	gridColumn: side === 'right' ? '3 / span 1' : 'grid-column: 1 / span 1',
	gridRow: isHeaderAboveSideImages ? '2 / span 1' : '1 / span 2',
	alignContent: 'center',
	justifySelf: 'center',
});

export const sideImageMobileWrapperStyles = {
	display: 'flex',
	flexFlow: 'row no-wrap',
	justifyContent: 'center',
	padding: '0 1.5rem',
};
