import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Form, TextInput, useHookForm } from '@aspendental/shared-forms-web';
import { FieldValues } from 'react-hook-form';

import TAGButton from '@/components/TAGButton';
import { useAppContext } from '@/context';

import {
	getFormFieldStyles,
	formWrapStyles,
	getTextFieldInputStyles,
	getTextFieldStyles,
} from './SubscriptionForm.styles';
import { schema } from './SubscriptionForm.helpers';

interface ISubscriptionForm {
	isDarkBackground?: boolean;
	isSmallScreen?: boolean;
	description?: string;
	descriptionPosition?: 'left' | 'bottom';
	additionalSubscriptionData?: Record<string, string> | null;
}

export default function SubscriptionForm({
	isDarkBackground = false,
	isSmallScreen = false,
	description,
	descriptionPosition = 'left',
	additionalSubscriptionData = null,
}: Readonly<ISubscriptionForm>) {
	const [status, setStatus] = useState<'success' | 'error' | null>(null);
	const { config } = useAppContext();
	const isBrazeEnabled = config.features.analytics.braze.enabled;
	const withBottomDescription: boolean = descriptionPosition === 'bottom' && Boolean(description);
	const withLeftDescription: boolean = descriptionPosition === 'left' && Boolean(description);
	const formDescription = description && (
		<Typography
			color={isDarkBackground ? 'text.light' : 'text.secondary'}
			variant="captionMedium"
			width={isSmallScreen || withBottomDescription ? '100%' : '40%'}
			data-test-id="subscription_form_description"
		>
			{description}
		</Typography>
	);

	const form = useHookForm({
		schema,
		defaultValues: {
			email: '',
		},
		mode: 'onSubmit',
	});
	const setEmailError = () => {
		form.setError('email', {
			type: 'manual',
			message: 'An error occurred. Please try again.',
		});
	};

	const onSubmit = async (values: FieldValues) => {
		if (!isBrazeEnabled) {
			setEmailError();
		}
		const CLIENT_LOOKUP_ENDPOINT = `${process.env.APP_PATH ?? ''}/api/submit-subscription-form`;
		try {
			const response = await fetch(CLIENT_LOOKUP_ENDPOINT, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					email: values.email as string,
					additionalSubscriptionData,
				}),
			});
			const result = (await response.json()) as { message?: string };
			if (result?.message === 'success') {
				form.reset();
				setStatus('success');
			} else {
				setEmailError();
			}
		} catch (e) {
			setEmailError();
		}
	};

	return (
		<Box
			minWidth={withLeftDescription && !isSmallScreen ? '50%' : '100%'}
			margin="0 auto"
			data-test-id="subscription_form_wrap"
		>
			<Form form={form} onSubmit={onSubmit} data-test-id="subscription_form">
				<Box sx={formWrapStyles}>
					{descriptionPosition === 'left' && formDescription}
					{(!status || status === 'error') && (
						<>
							<Box sx={getFormFieldStyles({ withBottomDescription, withLeftDescription })}>
								<TextInput
									name="email"
									placeholder="Your Email"
									enforceAlphaNumeric={false}
									size="small"
									sx={getTextFieldStyles(isDarkBackground)}
									inputProps={{
										sx: getTextFieldInputStyles(isDarkBackground),
									}}
									data-test-id="subscription_form_test_field"
									fullWidth
								/>
								{descriptionPosition === 'bottom' && formDescription}
							</Box>
							<TAGButton
								variant="primaryDefault"
								size="S"
								type="submit"
								dataTestId="subscription_form_submit_button"
							>
								Submit
							</TAGButton>
						</>
					)}
					{status === 'success' && (
						<Typography color="tertiary.main" variant="bodyMediumBook">
							Thank you for signing up!
						</Typography>
					)}
				</Box>
			</Form>
		</Box>
	);
}
