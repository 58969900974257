import { Box, Grid, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import {
	ColumnsColumnsCollection,
	Maybe,
	SingleMessage as TSingleMessage,
	ContentTypeRichText as TContentTypeRichText,
	ColumnsColumnsItem,
} from '@/types/generated';
import { DisableTemplatePaddingOn, ContentfulBackground, HeadingTag } from '@/types';
import SingleMessage from '@/components/SingleMessage';
import Accordions from '@/components/Accordions';
import {
	resolveContentfulBgColor,
	translateTagColorNameForContentful,
	useTemplateContainerFullWidthStyles,
	textToJumpLink,
} from '@/utils';
import resolveContentfulHeadingTag from '@/utils/resolveContentfulHeadingTag';

import TemplateContainer from '../TemplateContainer/TemplateContainer';
import ContentTypeRichText from '../ContentTypeRichText/ContentTypeRichText';
import { IAccordions } from '../Accordions/Accordions';
import ImageAsset from '../ImageAsset';
import { IImageAsset } from '../ImageAsset/ImageAsset';

import { columnItemBoxCtrStyles, getColumnBoxCtrStyles } from './Columns.styles';

export interface IColumnsProps {
	/**
	 * Column eyebrow text before Headline
	 * @type {string}
	 * @memberof IColumnsProps
	 * @default ''
	 *
	 * @example
	 * <Columns eyebrow="eyebrow text" />
	 *
	 **/
	eyebrow?: string;
	/**
	 * Column headline text
	 * @type {string}
	 * @memberof IColumnsProps
	 * @default ''
	 * @example
	 *  <Columns title="headline text" />
	 **/
	title?: string;
	/**
	 * Large text shown as the headline on large screens
	 *
	 * @type {Maybe<HeadingTag>}
	 * @memberof IColumnsProps
	 * @default 'h1'
	 */
	titleHtag?: Maybe<HeadingTag>;
	/**
	 * Column overall description which is below the headline
	 * @type {string}
	 * @memberof IColumnsProps
	 * @default ''
	 * @example
	 *  <Columns description="description text" />
	 **/
	description?: string;
	/**
	 * Background color for the section, and it will also override the background color of the child components.
	 * @type {ContentfulBackground | TAGBackground}
	 * @memberof IColumnsProps
	 * @default 'white'
	 * @example
	 * <Columns backgroundColor="white" />
	 * <Columns backgroundColor="dark" />
	 * <Columns backgroundColor="light" />
	 *
	 **/
	backgroundColor?: Maybe<ContentfulBackground | TAGBackground>;
	/**
	 * Columns contents collection (1-5 items).
	 * Could be RichText or SingleMessage
	 * @type {ColumnsColumnsCollection}
	 * @required
	 * @memberof IColumnsProps
	 * @example
	 * <Columns columnsCollection={columnsCollection} />
	 * where columnsCollection could be a collection of either:
	 * RichText
	 * SingleMessage
	 * min 1 item
	 * max 5 items
	 **/
	columnsCollection: ColumnsColumnsCollection;
	/**
	 * Whether the background color should cover the full width of the screen or not
	 * @type {boolean}
	 * @memberof IColumnsProps
	 * @default false
	 * @example
	 * <Columns fullWidth />
	 * @deprecated
	 **/
	fullWidth?: boolean;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IColumnsProps
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * Whether the Grid container of the columnItem content should be flex
	 * @type {boolean}
	 * @memberof IColumnsProps
	 * @default false
	 * @example
	 * <Columns allowEqualHeights />
	 **/
	allowEqualHeights?: boolean;
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {Maybe<string>}
	 * @memberof IColumnsProps
	 */
	anchorId?: Maybe<string>;

	/**
	 * Whether Grid items have vertical padding
	 * @type {boolean}
	 * @memberof IColumnsProps
	 * @default false
	 * @example
	 * <Columns disableItemPaddingOn />
	 **/
	disableItemYPaddingOn?: boolean;
	/**
	 * Whether Grid has l/r padding
	 * @type {boolean}
	 * @memberof IColumnsProps
	 * @default false
	 * @example
	 * <Columns disabledGutters />
	 **/
	disabledGutters?: boolean;
	/**
	 * Additional styles applied to the container
	 * @type {SxProps}
	 * @memberof IColumnsProps
	 * @default {}
	 **/
	containerSx?: SxProps;
}

const childBackgroundColor = (
	childComponent: TSingleMessage | IAccordions | TContentTypeRichText,
	backgroundColor?: IColumnsProps['backgroundColor']
) => {
	return backgroundColor?.toLowerCase() === 'transparent' ? childComponent?.backgroundColor : backgroundColor;
};

type TContentItemByTypeProps = {
	item: ColumnsColumnsItem;
	backgroundColor?: Maybe<IColumnsProps['backgroundColor']>;
	isDark?: Maybe<boolean>;
};
interface IContentItemRenderer {
	[key: string]: (...args: TContentItemByTypeProps[]) => JSX.Element | null;
}
const contentItemRenderer: IContentItemRenderer = {
	ContentTypeRichText: ({ item, backgroundColor, isDark }: TContentItemByTypeProps) => (
		<ContentTypeRichText
			{...{
				...(item as TContentTypeRichText),
				backgroundColor: childBackgroundColor(item as TContentTypeRichText, backgroundColor),
			}}
			disableGutters
			contentMaxWidth="100%"
			dataTestId={`section_content_type_rich_text_${item.sys.id}`}
			docProps={{
				color: isDark ? 'text.light' : 'text.secondary',
			}}
		/>
	),
	SingleMessage: ({ item, backgroundColor }: TContentItemByTypeProps) => (
		<SingleMessage
			{...(item as TSingleMessage)}
			backgroundColor={childBackgroundColor(item as TSingleMessage, backgroundColor)}
		/>
	),
	Accordions: ({ item, backgroundColor }: TContentItemByTypeProps) => (
		<Accordions
			{...(item as IAccordions)}
			backgroundColor={childBackgroundColor(item as IAccordions, backgroundColor)}
		/>
	),
	ImageAsset: ({ item }: TContentItemByTypeProps) => <ImageAsset {...(item as IImageAsset)} />,
};

const ContentItemByType = (props: TContentItemByTypeProps) => {
	const { item } = props;
	const typename = item?.__typename;
	if (!typename) return null;
	const hasRenderer = Object.keys(contentItemRenderer).includes(typename);
	return hasRenderer ? <>{contentItemRenderer[typename as string](props)}</> : null;
};

export default function Columns({
	backgroundColor = 'white',
	columnsCollection,
	description,
	eyebrow,
	title,
	titleHtag = 'h1',
	disablePaddingOn,
	allowEqualHeights,
	anchorId,
	disableItemYPaddingOn,
	disabledGutters,
	containerSx = {},
}: Readonly<IColumnsProps>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isDark = backgroundColor?.toLowerCase().includes('dark') || backgroundColor?.toLowerCase() === 'saffron';

	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor ?? '') || '';
	const contentfulBackgroundColor = resolveContentfulBgColor(
		contentfulSafeBackgroundColor as ContentfulBackground,
		theme
	);
	const columnItems = columnsCollection?.items || [];
	const wrapperStyles = useTemplateContainerFullWidthStyles(disablePaddingOn, isSmallScreen, disabledGutters);
	const showTopSection = !!(eyebrow ?? title ?? description);
	return (
		<TemplateContainer
			disableGutters={disabledGutters}
			maxWidth={false}
			containerSx={{ ...wrapperStyles, ...containerSx }}
			{...(anchorId && { id: textToJumpLink(anchorId) })}
		>
			<Box
				data-test-id="section_columns"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				sx={getColumnBoxCtrStyles(contentfulBackgroundColor)}
			>
				{showTopSection && (
					<Grid display="flex" flexDirection="column" textAlign="center" mb="20px">
						{eyebrow && (
							<Typography
								variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
								color={isDark ? 'text.light' : 'text.secondary'}
							>
								{eyebrow}
							</Typography>
						)}
						{title && (
							<Typography
								component={resolveContentfulHeadingTag(titleHtag)}
								variant="header1"
								color={isDark ? 'text.light' : 'text.primary'}
								sx={{ margin: '12px 0' }}
								data-test-id="columns_title"
							>
								{title}
							</Typography>
						)}
						{description && (
							<p>
								<Typography
									variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
									color={isDark ? 'text.light' : 'text.secondary'}
								>
									{description}
								</Typography>
							</p>
						)}
					</Grid>
				)}
				<Grid container columnSpacing={4} direction="row" wrap={`${isSmallScreen ? 'wrap' : 'nowrap'}`}>
					{columnItems?.length > 0 &&
						columnItems?.map((column, i) => {
							if (!column?.__typename) return null;
							return (
								<Grid
									item
									sx={columnItemBoxCtrStyles(disableItemYPaddingOn)}
									key={column._id}
									data-test-id={`section_columns_${i + 1}`}
									{...(allowEqualHeights && { display: 'flex' })}
								>
									<ContentItemByType
										item={column}
										backgroundColor={backgroundColor}
										isDark={isDark}
									/>
								</Grid>
							);
						})}
				</Grid>
			</Box>
		</TemplateContainer>
	);
}
