import { useState, ReactNode } from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { useMediaQuery, useTheme, Box, Typography } from '@mui/material';

import {
	CardRichTextDescriptionLinks,
	SectionContentRichTextContentLinks,
	ContentTypeRichTextSecondaryContentLinks,
	ContentTypeRichTextContentLinks,
	TextBlockSeoContentLinks,
	TermsAndConditionsContentLinks,
	InsuranceIntroLinks,
	TableFootnoteLinks,
	ContentSectionDescriptionLinks,
} from '@/types/generated';
import { incorrectUsageWarning } from '@/utils/miscUtils';
import TAGLink from '@/components/TAGLink';

import { getOptions } from './RichText.helpers';

export type RichTextContent = Document;

interface IRichText {
	/**
	 * Rich Text content background check coming from Contentful.
	 *
	 * @type {RichTextContent}
	 * @memberof IRichText
	 */
	isDark?: boolean;
	/**
	 * Optional props to pass to the RichText component. **Note that for multi-element rich text these props will be passed to all elements**.
	 *
	 * @type {Record<string, unknown>}
	 * @memberof IRichText
	 * @todo make it available to pass props to different types of rich text elements
	 * for example { paragraph: {...}, header1: {...}, ... } This will make styling complex rich text easier, but will require to know in advance what types of rich text elements are going to be present in the content.
	 */
	docProps?: Record<string, unknown>;
	/**
	 * Rich Text content coming from Contentful.
	 *
	 * @type {RichTextContent}
	 * @memberof IRichText
	 */
	content: RichTextContent;
	/**
	 * Secondary Rich Text content coming from Contentful.
	 *
	 * @type {RichTextContent}
	 * @memberof IRichText
	 */
	secondaryContent?: RichTextContent;
	/**
	 * Links object containing additional data about entries and assets linked in the rich text.
	 *
	 * @type {SectionContentRichTextContentLinks}
	 * @memberof IRichText
	 */
	links?:
		| CardRichTextDescriptionLinks
		| SectionContentRichTextContentLinks
		| ContentTypeRichTextContentLinks
		| TextBlockSeoContentLinks
		| TermsAndConditionsContentLinks
		| InsuranceIntroLinks
		| TableFootnoteLinks
		| ContentSectionDescriptionLinks;
	/**
	 * Secondary Links object containing additional data about entries and assets linked in the secondary rich text.
	 *
	 * @type {ContentTypeRichTextSecondaryContentLinks}
	 * @memberof IRichText
	 */
	secondaryLinks?: ContentTypeRichTextSecondaryContentLinks;
	/**
	 * Display SecondaryContent in DOM for SEO purposes
	 *
	 * @type {boolean}
	 * @memberof IContentTypeRichText
	 */
	displaySecondaryContentInDom?: boolean;
}

export default function RichText({
	isDark = false,
	content,
	links,
	secondaryContent,
	secondaryLinks,
	docProps = {},
	displaySecondaryContentInDom = false,
}: IRichText) {
	const [state, setState] = useState({
		expanded: false,
	});

	const { expanded } = state;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!content) {
		incorrectUsageWarning('RichText', 'The content prop is missing.');
		return null;
	}

	const primaryRichContent = documentToReactComponents(
		content,
		getOptions({ docProps, links, isDark, theme, isSmallScreen })
	) as ReactNode[];

	const secondaryRichContent =
		secondaryContent &&
		(documentToReactComponents(
			secondaryContent,
			getOptions({ docProps, links: secondaryLinks, isDark, theme, isSmallScreen })
		) as ReactNode[]);

	const handleMoreOrLess = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		e.preventDefault();
		setState({
			...state,
			expanded: !state.expanded,
		});
	};

	// this will add more/less link to show/hide text
	const addMoreOrLessLink = () => {
		const linkText = expanded ? '...Less' : '...More';
		const primaryRTLastElement = Object.assign({}, primaryRichContent?.[primaryRichContent.length - 1]) as {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		};
		const secondaryRTLastElement = Object.assign({}, secondaryRichContent?.[secondaryRichContent.length - 1]) as {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		};

		const lastElementToAddMoreOrLess = expanded ? secondaryRTLastElement : primaryRTLastElement;
		const elementsToRender =
			secondaryRichContent && expanded ? [...primaryRichContent, ...secondaryRichContent] : primaryRichContent;

		const updatedLastElement = {
			...lastElementToAddMoreOrLess,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			props: {
				...lastElementToAddMoreOrLess?.props,
				children: [
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-unsafe-optional-chaining, @typescript-eslint/no-unsafe-member-access
					...lastElementToAddMoreOrLess?.props?.children,
					<Typography
						variant="bodyMediumBook"
						color={isDark ? 'text.light' : 'text.interactive'}
						key={`${linkText}-link`}
					>
						<TAGLink onClick={handleMoreOrLess} dataTestId={`link_ellipsis_${linkText}`}>
							{linkText}
						</TAGLink>
					</Typography>,
				],
			},
		};

		return [...elementsToRender.slice(0, -1), updatedLastElement];
	};

	// secondary content available in dom only when clicking more
	const richTextContent = secondaryRichContent ? addMoreOrLessLink() : primaryRichContent;

	// secondary content available in dom but hidden using css for crawlers to read the content
	// example rich text block seo content
	const richTextContentWithSecondaryContentInDom = (
		<>
			{primaryRichContent}
			{!expanded && (
				<Typography variant="bodyMediumBook" color={isDark ? 'text.light' : 'text.interactive'}>
					<TAGLink onClick={handleMoreOrLess} dataTestId={`link_ellipsis_...More-link}`}>
						...More
					</TAGLink>
				</Typography>
			)}
			<Box sx={{ display: expanded ? 'block' : 'none' }}>
				{secondaryRichContent}
				<Typography variant="bodyMediumBook" color={isDark ? 'text.light' : 'text.interactive'}>
					<TAGLink onClick={handleMoreOrLess} dataTestId={`link_ellipsis_...Less-link}`}>
						...Less
					</TAGLink>
				</Typography>
			</Box>
		</>
	);

	return <>{displaySecondaryContentInDom ? richTextContentWithSecondaryContentInDom : richTextContent}</>;
}
