import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import {
	AccordionsAccordionsCollection,
	AccordionsButtonsCollection,
	Asset,
	Maybe,
	Accordions as TAccordions,
	FocalPointImage as TFocalPointImage,
	AccordionsDescriptionRichText,
	ContentTypeRichTextContent as TContentTypeRichTextContent,
} from '@/types/generated';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import {
	ContentfulBackground,
	HeadingTag,
	DisableTemplatePaddingOn,
	TAccordionExpandIconPosition,
	TAccordionExpandIconType,
	TRoundedCornersSize,
} from '@/types';
import { resolveContentfulBgColor, useTemplateContainerFullWidthStyles, textToJumpLink } from '@/utils';
import ContentTypeRichText from '@/components/ContentTypeRichText';

import TemplateContainer from '../TemplateContainer/TemplateContainer';

import AccordionContainer from './AccordionContainer';
import { baseSectionContainerStyles, richTextDescStyles, titleStyles } from './Accordions.styles';

export interface IAccordions extends TAccordions {
	/**
	 * The collection of accordions. Currently, only RichText template is supported.
	 *
	 * @type {Maybe<AccordionsAccordionsCollection>}
	 * @memberof IAccordions
	 */
	accordionsCollection?: Maybe<AccordionsAccordionsCollection>;
	/**
	 * Background color for the section.
	 * @type {Maybe<TAGBackground> | Maybe<ContentfulBackground>}
	 * @memberof IAccordions
	 * @default 'white'
	 **/
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies whether the template should be centered or not. Not used.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IAccordions
	 * @deprecated
	 */
	centerAlign?: Maybe<boolean>;
	/**
	 * The Heading Tag for the template.
	 *
	 * @type {Maybe<string>}
	 * @memberof IAccordions
	 */
	titleHtag?: Maybe<string>;
	/**
	 * This option determines if the title will be text aligned center or left. By default, text will align center.
	 *
	 * @type {Maybe<Left | Center>}
	 * @memberof IAccordions
	 */
	titleAlignment?: Maybe<string>;
	/**
	 * Template section rich text description
	 *
	 * @type {Maybe<AccordionsDescriptionRichText>}
	 * @memberof IAccordions
	 */
	descriptionRichText?: Maybe<AccordionsDescriptionRichText>;
	/**
	 * This option determines if the description will be text aligned center or left. By default, text will align center.
	 *
	 * @type {Maybe<Left | Center>}
	 * @memberof IAccordions
	 */
	descriptionAlignment?: Maybe<string>;
	/**
	 * Specifies whether the template should be full width or not. Not used.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IAccordions
	 * @deprecated
	 */
	fullWidth?: Maybe<boolean>;
	/**
	 * The image to display. The reference to an image asset in Contentful.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IAccordions
	 */
	backgroundImage?: Maybe<Asset>;

	/**
	 * Disables the default side padding of the Container component.
	 *
	 * @type {boolean}
	 * @memberof IAccordions
	 */
	disableGutters?: boolean;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof IAccordions
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * Specifies whether the template should use the image or not. Not used.
	 *
	 * @type {Maybe<string>}
	 * @memberof IAccordions
	 * @deprecated use RichText or Services template instead.
	 */
	image?: Maybe<TFocalPointImage>;
	/**
	 * Specifies whether the image should be positioned to the right or not. Not used.
	 *
	 * @type {Maybe<string>}
	 * @memberof IAccordions
	 * @deprecated use RichText or Services template instead.
	 */
	imagePositionRight?: Maybe<boolean>;
	/**
	 * Specifies whether the image should be rounded or not. Not used.
	 *
	 * @type {Maybe<string>}
	 * @memberof IAccordions
	 * @deprecated use RichText or Services template instead.
	 */
	imageRoundedCorner?: Maybe<string>;
	/**
	 * The title of the section.
	 *
	 * @type {Maybe<string>}
	 * @memberof IAccordions
	 */
	title?: Maybe<string>;
	/**
	 * The color of the title.
	 *
	 * @type {Maybe<primary | secondary | accent01 | accent02>}
	 * @memberof IAccordions
	 */
	titleColor?: Maybe<string>;

	buttonsCollection?: Maybe<AccordionsButtonsCollection>;

	/**
	 * Specifies the buttons collection alignement
	 *
	 * @type {Maybe<Center | Left>}
	 * @memberof IAccordions
	 */
	buttonCollectionAlignment?: Maybe<string>;

	/**
	 * Specifies the expand icon position left or right
	 *
	 * @type {Maybe<TAccordionExpandIconPosition>}
	 * @memberof IAccordions
	 */
	expandIconPosition?: Maybe<TAccordionExpandIconPosition>;
	/**
	 * Specifies the expand icon type default or plus / minus
	 *
	 * @type {Maybe<TAccordionExpandIconType>}
	 * @memberof IAccordions
	 */
	expandIconType?: Maybe<TAccordionExpandIconType>;
	/**
	 * With gap inside an accordion
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IAccordions
	 */
	withInnerGap?: Maybe<boolean>;
	/**
	 * Large, Medium or Small type of rounded corners
	 *
	 * @type {Maybe<TRoundedCornersSize>}
	 * @memberof IAccordions
	 */
	roundedCornersSize?: Maybe<TRoundedCornersSize>;
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {Maybe<string>;}
	 * @memberof IAccordions
	 */
	anchorId?: Maybe<string>;
}

export default function Accordions({
	sys,
	title,
	descriptionRichText,
	titleColor,
	accordionsCollection,
	backgroundColor = 'White',
	titleHtag = 'h1',
	buttonsCollection,
	buttonCollectionAlignment = 'Center',
	backgroundImage,
	titleAlignment = 'Center',
	descriptionAlignment = 'Center',
	disableGutters = false,
	disablePaddingOn,
	expandIconPosition = 'Left',
	expandIconType = 'Expand',
	withInnerGap = false,
	roundedCornersSize = 'Small',
	anchorId,
}: Readonly<IAccordions>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
	const accordionsItems = accordionsCollection?.items;
	const isDark = backgroundColor?.toLowerCase().includes('dark') || false;
	const bgColor = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);
	const wrapperStyles = useTemplateContainerFullWidthStyles(
		disablePaddingOn as DisableTemplatePaddingOn,
		isMediumScreen,
		disableGutters
	);

	return (
		<TemplateContainer
			disableGutters={disableGutters}
			maxWidth={false}
			dataTestId={`section_accordions_${sys.id}`}
			containerSx={{
				...(backgroundImage && {
					backgroundImage: `url('${backgroundImage?.url ?? ''}')`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}),
				...wrapperStyles,
			}}
			contentfulBackgroundColor={bgColor as Maybe<ContentfulBackground>}
			backgroundColor={backgroundColor as Maybe<TAGBackground>}
			{...(anchorId && { id: textToJumpLink(anchorId) })}
		>
			<Box sx={{ ...baseSectionContainerStyles }} data-test-id={`section_carousel_info_${sys.id}`}>
				{title && (
					<Typography
						variant={getHeadingVariant(titleHtag as HeadingTag)}
						whiteSpace="pre-line"
						color={isDark ? 'text.light' : `${titleColor || `primary`}.dark`}
						data-test-id="text_accordion_section_title"
						sx={{ ...titleStyles(titleAlignment) }}
					>
						{title}
					</Typography>
				)}

				{descriptionRichText ? (
					<ContentTypeRichText
						content={descriptionRichText as TContentTypeRichTextContent}
						dataTestId={`text_accordions_section_description_rich_text`}
						disableGutters
						backgroundColor="transparent"
						disablePaddingOn={['Top', 'Bottom']}
						contentMaxWidth="100%"
						docProps={{
							variant: isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook',
							color: isDark ? 'text.light' : 'text.secondary',
							textAlign: descriptionAlignment === 'Center' ? 'center' : 'left',
						}}
						containerSx={richTextDescStyles(isDark)}
					/>
				) : null}
			</Box>

			{accordionsItems && (
				<AccordionContainer
					items={accordionsItems}
					isSmallScreen={isSmallScreen}
					buttonsCollection={buttonsCollection}
					buttonCollectionAlignment={buttonCollectionAlignment}
					isDark={isDark}
					expandIconPosition={expandIconPosition}
					expandIconType={expandIconType}
					withInnerGap={withInnerGap}
					roundedCornersSize={roundedCornersSize}
					titleHtag={titleHtag}
				/>
			)}
		</TemplateContainer>
	);
}
